import React from "react"
import Img from "gatsby-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./news.scss"

export default ({ data }) => {
  const { t } = useTranslation()
  return (
    <Layout className="page news">
      <SEO title="Aktuality" />
      <h1>{t("news.headline")}</h1>
      <br />
      <div className="news__item" style={{ marginBottom: "2rem" }}>
        <div className="news__content">
          <h2>{t("news.news1.headline")}</h2>
          <div dangerouslySetInnerHTML={{ __html: t("news.news1.content") }} />
        </div>
      </div>
      {/* <div className="news__item">
        <div className="news__content">
          <h2>{t("news.news1.headline")}</h2>
          <div dangerouslySetInnerHTML={{ __html: t("news.news1.content") }} />
        </div>
        <Img fluid={data.file.childImageSharp.fluid} alt="David Suchý" />
      </div> */}
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    file(relativePath: { eq: "bajkazyl-okno.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
